<template>
  <div class="globalPage">
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Title & Favicon settings</label>
    <div >
      <label class="label">Change Title</label>
      <v-text-field
          class="input input_dense"
          outlined
          solo
          style="width:50%;"
          flat
          dense
          v-model="title"
          @change="changeTitle"
          @input="changeTitleAdvanced"
      ></v-text-field>
    </div>
    <div class=" upload-icon">
      <label class="label">Change favicon</label>
      <vue-dropzone
          id="upload"
          ref="upload"
          :options="dropzoneOptions"
          :destroyDropzone="false"
          @vdropzone-sending="customEvent"
          @vdropzone-removed-file="removeFavicon"
          @vdropzone-success="afterCompleteFavicon"
          @vdropzone-mounted="mountDropzone"
          @vdropzone-max-files-exceeded="faviconMaxFileSexceeded"

      />
    </div>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "GlobalPage",
  components:{
    vueDropzone:() => import("vue2-dropzone"),
  },
  computed:{
    ...mapState(['landing']),
  },
  data(){
    return{
      title:"",
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 0.2,
        maxFiles: 1,
        destroyDropzone: false,
        dictRemoveFile:'x'
      },
    }
  },
  methods:{
    ...mapMutations('landing',['setLandingTitle']),
    ...mapMutations('advanced',['setTitle']),
    removeFavicon(){
      this.landing.advanced.global.favicon_url = '';
    },
    changeTitleAdvanced(){
      this.setTitle(this.title);
    },
    changeTitle(){
      this.setLandingTitle(this.title);
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    afterCompleteFavicon(file) {
      this.landing.advanced.global.favicon_url = file.xhr.response;
    },
    faviconMaxFileSexceeded(file) {
      this.$refs.upload.removeAllFiles();
      this.$refs.upload.addFile(file);
    },
    mountDropzone(){
      if(this.landing.advanced.global.favicon_url){
        let mockFile = {
          accepted: true,
          kind: "image",
          ...this.landing.advanced.global.favicon_url,
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.landing.advanced.global.favicon_url);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.landing.advanced.global.favicon_url);
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }
    }
  },
  mounted(){
    this.title = this.landing.advanced.global.title;
    this.changeTitleAdvanced()
  }
}
</script>

<style lang="scss">
.upload-icon {
  & .dropzone {
    & .dz-preview {
      & .dz-image {
        & img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
      }
      & .dz-error-message{
        width:200px;
        top:90px;
      }
      & .dz-remove{
        margin-left: 9px;
        padding: 5px 10px;
        bottom: 8px;
      }
    }
  }
}
</style>
